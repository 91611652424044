//import SessionString from '../../Common/js/sessionString';


export default class ProjectResource {
    static siteID = null;

    static path = {
        root: "/",
        Directions: '/directions', 
        CompanySection: '/companySection',
        PerformanceSection: "/performanceSection",
        BusinessSection: "/businessSection",
        Report: "/report", 
        ContactSection: "/contactSection",
        ProductSection: "/productSection",
    }
}