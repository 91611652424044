import React, { Component } from 'react';
import { Link } from "react-router-dom";
import pIntro from '../ProductIntro/css/product.module.css';
import home from '../components/css/home.module.css';
import $ from 'jquery';

import AOS from "aos";
import "aos/dist/aos.css";
//import Resource from '../resource/id';

class ProductSectionKor extends Component {
    static displayName = ProductSectionKor.name;

    constructor(props) {
        super(props);

        this.state = {
            //disProductUI: null,
        }

        this.state.disProductUI = this.displayProductUI;
    }

    resizeUI() {
        this.setState({ disProductUI: this.displayProductUI() });
    }

    componentDidMount() {
        $(document).ready(function () {
            AOS.init();
        });

        $('.' + pIntro.dropdown).mouseover(function () {
            $('.' + pIntro.dropdownContent).show();
        });

        $('.' + pIntro.cMenu).mouseleave(function () {
            $('.' + pIntro.dropdownContent).hide();
        });

        $('#dropdownContent').click(function () {
            $('#dropdownContent span').hide();
        });

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });

        $(function () {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 500) {
                    $('#toTop').show();
                }
            });
            $(window).scroll(function () {
                if ($(this).scrollTop() > 8000) {
                    $('#toTop').hide();
                }
            });
            $('#toTop').click(function () {
                $('html, body').animate({
                    scrollTop: 0
                }, 300);
                return false;
            });
        });
    }

    displayProductUI = () => {
        let displayProductUI = [];
        let widthSize = window.outerWidth;

        if (widthSize < 768) {
            displayProductUI.push(
                <>
                    <div className={pIntro.contentBox}>
                        <div className={pIntro.productIntroBox} id="protecto">
                            <span className={pIntro.loadingImage}></span>
                            <span className={pIntro.comingText}>Coming soon</span>
                            <span className={pIntro.pageText}>페이지<p>준비중</p>입니다.</span>
                            <p>빠르고 유연하며 기능적인 디지털 트윈 통합 모니터링 플랫폼</p>
                            <p>PROTECTO를 세상에 선보이기 위해 열심히 준비하고 있습니다.</p>
                            <p>조금만 기다려 주세요!</p>
                            <a href="../../resource/protecto 소개자료_v2.1.pdf" download>
                                <span className={pIntro.productFile}>Protecto 소개자료 다운로드
                                    <span className={pIntro.productFileImg}></span>
                                </span>
                            </a>
                        </div>

                        <div className={home.footBox}>
                            <div className={home.footLeftArea}>
                                <div className={home.footTitleBox}>
                                    <span className={home.footTitle}>(주)유엔이</span>
                                    <a href="../../resource/une_companyInfo_2024.pdf" download>
                                        <span className={home.companyDown}>
                                            <span className={home.companyDownText}>회사소개서 다운로드</span>
                                            <span className={home.companyImg}></span>
                                        </span>
                                    </a>
                                </div>
                                <div className={home.footContents}>
                                    <span>사업자등록번호 : 502-86-09535</span>
                                    <div className={home.footConTop}>
                                        <span>전화번호 : 02-714-4133</span>
                                        <span>팩스번호 : 02-714-4134</span>
                                    </div>
                                    <span className={home.footEmail}>문의메일 :<a href="mailto:plan@unes.co.kr" style={{ color: '#666666', marginLeft: '6px' }}>plan@unes.co.kr</a></span>
                                    <div className={home.footConBottom}>
                                        <span className={home.footBold}>서울지사 : 서울 용산구 청파로 345, 주연빌딩 1층 (우:04303)</span>
                                        <span><Link to="/directions">오시는길</Link></span>
                                    </div>
                                    <span>본사 : 대구 달서구 달구벌대로 1053, 계명대학교 첨단산업지원센터 108호 (우:42601)</span>
                                </div>
                                <div className={home.footContents2}>
                                    <span className={home.footText}>CopyrightⓒU&E All rights reserved.</span>
                                </div>
                            </div>
                            <div className={home.footIconArea}>
                                <div className={home.footIconBox}>
                                    <span><a target="_blank" href="https://www.youtube.com/channel/UC_DmpJ1xIYW9faxTi1M8TMQ"><span className={home.footYouTube}></span></a></span>
                                    <span><a target="_blank" href="https://www.instagram.com/unes.kr"><span className={home.footinstagram}></span></a></span>
                                    <span><a target="_blank" href="https://www.facebook.com/%EC%9C%A0%EC%97%94%EC%9D%B4-100778369074049"><span className={home.footFacebook}></span></a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (640 <= widthSize && widthSize <= 959) {  /* 가로 모바일 */
            displayProductUI.push(
                <>
                    <div className={pIntro.contentBox}>
                        <div className={pIntro.productIntroBox} id="protecto">
                            <span className={pIntro.loadingImage}></span>
                            <span className={pIntro.comingText}>Coming soon</span>
                            <span className={pIntro.pageText}>페이지<p>준비중</p>입니다.</span>
                            <p>빠르고 유연하며 기능적인 디지털 트윈 통합 모니터링 플랫폼</p>
                            <p>PROTECTO를 세상에 선보이기 위해 열심히 준비하고 있습니다.</p>
                            <p>조금만 기다려 주세요!</p>
                            <a href="../../resource/protecto 소개자료_v2.1.pdf" download>
                                <span className={pIntro.productFile}>Protecto 소개자료 다운로드
                                    <span className={pIntro.productFileImg}></span>
                                </span>
                            </a>
                        </div>

                        <div className={home.footBox}>
                            <div className={home.footLeftArea}>
                                <div className={home.footTitleBox}>
                                    <span className={home.footTitle}>(주)유엔이</span>
                                    <a href="../../resource/une_companyInfo_2024.pdf" download>
                                        <span className={home.companyDown}>
                                            <span className={home.companyDownText}>회사소개서 다운로드</span>
                                            <span className={home.companyImg}></span>
                                        </span>
                                    </a>
                                </div>
                                <div className={home.footContents}>
                                    <span>사업자등록번호 : 502-86-09535</span>
                                    <div className={home.footConTop}>
                                        <span>전화번호 : 02-714-4133</span>
                                        <span>팩스번호 : 02-714-4134</span>
                                    </div>
                                    <span className={home.footEmail}>문의메일 :<a href="mailto:plan@unes.co.kr" style={{ color: '#666666', marginLeft: '6px' }}>plan@unes.co.kr</a></span>
                                    <div className={home.footConBottom}>
                                        <span className={home.footBold}>서울지사 : 서울 용산구 청파로 345, 주연빌딩 1층 (우:04303)</span>
                                        <span><Link to="/directions">오시는길</Link></span>
                                    </div>
                                    <span>본사 : 대구 달서구 달구벌대로 1053, 계명대학교 첨단산업지원센터 108호 (우:42601)</span>
                                </div>
                                <div className={home.footContents2}>
                                    <span className={home.footText}>CopyrightⓒU&E All rights reserved.</span>
                                </div>
                            </div>
                            <div className={home.footIconArea}>
                                <div className={home.footIconBox}>
                                    <span><a target="_blank" href="https://www.youtube.com/channel/UC_DmpJ1xIYW9faxTi1M8TMQ"><span className={home.footYouTube}></span></a></span>
                                    <span><a target="_blank" href="https://www.instagram.com/unes.kr"><span className={home.footinstagram}></span></a></span>
                                    <span><a target="_blank" href="https://www.facebook.com/%EC%9C%A0%EC%97%94%EC%9D%B4-100778369074049"><span className={home.footFacebook}></span></a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (768 <= widthSize && widthSize <= 1023) { //태블릿
            displayProductUI.push(
                <>
                    <div className={pIntro.contentBox}>
                        <div className={pIntro.productIntroBox} id="protecto">
                            <span className={pIntro.loadingImage}></span>
                            <span className={pIntro.comingText}>Coming soon</span>
                            <span className={pIntro.pageText}>페이지<p>준비중</p>입니다.</span>
                            <p>빠르고 유연하며 기능적인 디지털 트윈 통합 모니터링 플랫폼</p>
                            <p>PROTECTO를 세상에 선보이기 위해 열심히 준비하고 있습니다.</p>
                            <p>조금만 기다려 주세요!</p>
                            <a href="../../resource/protecto 소개자료_v2.1.pdf" download>
                                <span className={pIntro.productFile}>Protecto 소개자료 다운로드
                                    <span className={pIntro.productFileImg}></span>
                                </span>
                            </a>
                        </div>

                        {/* Footer */}
                        <div className={home.footBox}>
                            <div className={home.footLeftArea}>
                                <div className={home.footTitleBox}>
                                    <span>(주)유엔이</span>
                                </div>
                                <div className={home.footContents}>
                                    <div className={home.footConTop}>
                                        <span>사업자등록번호 : 502-86-09535</span>
                                        <span className={home.footBorder}></span>
                                        <span>전화번호 : 02-714-4133</span>
                                        <span className={home.footBorder}></span>
                                        <span>팩스번호 : 02-714-4134</span>
                                        <span className={home.footBorder}></span>
                                        <span className={home.footEmail}>문의메일 :<a href="mailto:plan@unes.co.kr" style={{ color: '#666666', marginLeft: '6px' }}>plan@unes.co.kr</a></span>
                                    </div>
                                    <div className={home.footConBottom}>
                                        <span className={home.footBold}>서울지사 : 서울 용산구 청파로 345, 주연빌딩 1층 (우:04303)</span>
                                        <span className={home.footBorder}></span>
                                        <span>본사 : 대구 달서구 달구벌대로 1053, 계명대학교 첨단산업지원센터 108호 (우:42601)</span>
                                        <span><Link to="/directions">오시는길</Link></span>
                                    </div>
                                </div>
                                <div className={home.footContents2}>
                                    <span className={home.footText}>CopyrightⓒU&E All rights reserved.</span>
                                </div>
                            </div>
                            <div className={home.footIconArea}>
                                <a href="../../resource/une_companyInfo_2024.pdf" download>
                                    <span className={home.companyDown}>회사소개서 다운로드
                                        <span className={home.companyImg}></span>
                                    </span>
                                </a>
                                <div className={home.footIconBox}>
                                    <span><a target="_blank" href="https://www.youtube.com/channel/UC_DmpJ1xIYW9faxTi1M8TMQ"><span className={home.footYouTube}></span></a></span>
                                    <span><a target="_blank" href="https://www.instagram.com/unes.kr"><span className={home.footinstagram}></span></a></span>
                                    <span><a target="_blank" href="https://www.facebook.com/%EC%9C%A0%EC%97%94%EC%9D%B4-100778369074049"><span className={home.footFacebook}></span></a></span>
                                </div>
                                <div>

                                    {/*  <span className={home.korBtn} onClick={this.onClickKOR}>KOR</span>
                                                <span className={home.engBtn} onClick={this.onClickENG}>ENG</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (960 <= widthSize && widthSize <= 1280) { //가로 태블릿
            displayProductUI.push(
                <>
                    <div className={pIntro.contentBox}>
                        <div className={pIntro.productIntroBox} id="protecto">
                            <span className={pIntro.loadingImage}></span>
                            <span className={pIntro.comingText}>Coming soon</span>
                            <span className={pIntro.pageText}>페이지<p>준비중</p>입니다.</span>
                            <p>빠르고 유연하며 기능적인 디지털 트윈 통합 모니터링 플랫폼</p>
                            <p>PROTECTO를 세상에 선보이기 위해 열심히 준비하고 있습니다.</p>
                            <p>조금만 기다려 주세요!</p>
                            <a href="../../resource/protecto 소개자료_v2.1.pdf" download>
                                <span className={pIntro.productFile}>Protecto 소개자료 다운로드
                                    <span className={pIntro.productFileImg}></span>
                                </span>
                            </a>
                        </div>

                        {/* Footer */}
                        <div className={home.footBox}>
                            <div className={home.footLeftArea}>
                                <div className={home.footTitleBox}>
                                    <span>(주)유엔이</span>
                                </div>
                                <div className={home.footContents}>
                                    <div className={home.footConTop}>
                                        <span>사업자등록번호 : 502-86-09535</span>
                                        <span className={home.footBorder}></span>
                                        <span>전화번호 : 02-714-4133</span>
                                        <span className={home.footBorder}></span>
                                        <span>팩스번호 : 02-714-4134</span>
                                        <span className={home.footBorder}></span>
                                        <span className={home.footEmail}>문의메일 :<a href="mailto:plan@unes.co.kr" style={{ color: '#666666', marginLeft: '6px' }}>plan@unes.co.kr</a></span>
                                    </div>
                                    <div className={home.footConBottom}>
                                        <span className={home.footBold}>서울지사 : 서울 용산구 청파로 345, 주연빌딩 1층 (우:04303)</span>
                                        <span className={home.footBorder}></span>
                                        <span>본사 : 대구 달서구 달구벌대로 1053, 계명대학교 첨단산업지원센터 108호 (우:42601)</span>
                                        <span><Link to="/directions">오시는길</Link></span>
                                    </div>
                                </div>
                                <div className={home.footContents2}>
                                    <span className={home.footText}>CopyrightⓒU&E All rights reserved.</span>
                                </div>
                            </div>
                            <div className={home.footIconArea}>
                                <a href="../../resource/une_companyInfo_2024.pdf" download>
                                    <span className={home.companyDown}>회사소개서 다운로드
                                        <span className={home.companyImg}></span>
                                    </span>
                                </a>
                                <div className={home.footIconBox}>
                                    <span><a target="_blank" href="https://www.youtube.com/channel/UC_DmpJ1xIYW9faxTi1M8TMQ"><span className={home.footYouTube}></span></a></span>
                                    <span><a target="_blank" href="https://www.instagram.com/unes.kr"><span className={home.footinstagram}></span></a></span>
                                    <span><a target="_blank" href="https://www.facebook.com/%EC%9C%A0%EC%97%94%EC%9D%B4-100778369074049"><span className={home.footFacebook}></span></a></span>
                                </div>
                                <div>

                                    {/*  <span className={home.korBtn} onClick={this.onClickKOR}>KOR</span>
                                                <span className={home.engBtn} onClick={this.onClickENG}>ENG</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (widthSize >= 1024) {
            displayProductUI.push(
                <>
                    <div className={pIntro.contentBox}>
                        <div className={pIntro.productIntroBox} id="protecto">
                            <span className={pIntro.loadingImage}></span>
                            <span className={pIntro.comingText}>Coming soon</span>
                            <span className={pIntro.pageText}>페이지<p>준비중</p>입니다.</span>
                            <p>빠르고 유연하며 기능적인 디지털 트윈 통합 모니터링 플랫폼</p>
                            <p>PROTECTO를 세상에 선보이기 위해 열심히 준비하고 있습니다.</p>
                            <p>조금만 기다려 주세요!</p>
                            <a href="../../resource/protecto 소개자료_v2.1.pdf" download>
                                <span className={pIntro.productFile}>Protecto 소개자료 다운로드
                                    <span className={pIntro.productFileImg}></span>
                                </span>
                            </a>
                        </div>

                        {/* Footer */}
                        <div className={home.footBox}>
                            <div className={home.footLeftArea}>
                                <div className={home.footTitleBox}>
                                    <span>(주)유엔이</span>
                                </div>
                                <div className={home.footContents}>
                                    <div className={home.footConTop}>
                                        <span>사업자등록번호 : 502-86-09535</span>
                                        <span className={home.footBorder}></span>
                                        <span>전화번호 : 02-714-4133</span>
                                        <span className={home.footBorder}></span>
                                        <span>팩스번호 : 02-714-4134</span>
                                        <span className={home.footBorder}></span>
                                        <span className={home.footEmail}>문의메일 :<a href="mailto:plan@unes.co.kr" style={{ color: '#666666', marginLeft: '6px' }}>plan@unes.co.kr</a></span>
                                    </div>
                                    <div className={home.footConBottom}>
                                        <span className={home.footBold}>서울지사 : 서울 용산구 청파로 345, 주연빌딩 1층 (우:04303)</span>
                                        <span className={home.footBorder}></span>
                                        <span>본사 : 대구 달서구 달구벌대로 1053, 계명대학교 첨단산업지원센터 108호 (우:42601)</span>
                                        <span><Link to="/directions">오시는길</Link></span>
                                    </div>
                                </div>
                                <div className={home.footContents2}>
                                    <span className={home.footText}>CopyrightⓒU&E All rights reserved.</span>
                                </div>
                            </div>
                            <div className={home.footIconArea}>
                                <a href="../../resource/une_companyInfo_2024.pdf" download>
                                    <span className={home.companyDown}>회사소개서 다운로드
                                        <span className={home.companyImg}></span>
                                    </span>
                                </a>
                                <div className={home.footIconBox}>
                                    <span><a target="_blank" href="https://www.youtube.com/channel/UC_DmpJ1xIYW9faxTi1M8TMQ"><span className={home.footYouTube}></span></a></span>
                                    <span><a target="_blank" href="https://www.instagram.com/unes.kr"><span className={home.footinstagram}></span></a></span>
                                    <span><a target="_blank" href="https://www.facebook.com/%EC%9C%A0%EC%97%94%EC%9D%B4-100778369074049"><span className={home.footFacebook}></span></a></span>
                                </div>
                                <div>

                                    {/*  <span className={home.korBtn} onClick={this.onClickKOR}>KOR</span>
                                                <span className={home.engBtn} onClick={this.onClickENG}>ENG</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            displayProductUI.push(
                <></>
            );
        }
        return displayProductUI;
    }


    render() {

        setTimeout(() => { this.resizeUI() }, 500);
        let displayProductUI = this.state.disProductUI;

        return (
            <>
                {displayProductUI}
            </>
        );
    }
}

export default ProductSectionKor;